<template>
    <div>
        <div class="top">
            <div class="title">{{ data.Assessment_Name }}</div>
            <div class="tips">{{ data.Assessment_Description }}</div>
            <div class="info">
                <span>考核时间：{{ $moment(data.Answer_Start_Time).format('YYYY-MM-DD') }}~{{
                $moment(data.Answer_End_Time).format('YYYY-MM-DD') }}</span>
                <span>发起人：{{ data.Publish_User_Name }}</span>
            </div>
        </div>
        <div class="center">
            <div class="item" v-for="(item, index) in data.TemplateParents" :key="index">
                <div class="bigTit">{{ item.Parent_Title }}({{ item.Parent_Score_Max }}分)</div>
                <div class="bigTopic" v-if="item.Is_SetScore == 1">
                    <van-field v-model="item.Self_Assessment_Score"
                        v-if="data.AssessmentType == 1 || data.AssessmentType == 2" type="number"
                        class="fontash" label="自评分:" placeholder="请输入" readonly />

                    <van-field v-model="item.ManUser_Score"
                        v-if="(data.AssessmentType == 1 && data.IsSelfComplate == 1) || data.AssessmentType == 3"
                        type="number" label="考核评分：" placeholder="请输入" readonly
                        :class="item.ManUser_Score == item.Parent_Score_Max ? '' : 'fontred'" />
                </div>

                <div class="childBox" v-if="item.Children.length">
                    <div class="child" v-for="(val, ind) in item.Children" :key="ind">
                        <div class="smallTit">{{ val.Child_Title }}(标准分{{ val.Child_Score_Max }}分)</div>
                        <div class="smallTopic">
                            <van-field v-model="val.Self_Assessment_Score"
                                v-if="data.AssessmentType == 1 || data.AssessmentType == 2" type="number"
                                class="fontash" label="自评分:" placeholder="请输入" readonly />

                            <van-field v-model="val.ManUser_Score"
                                v-if="(data.AssessmentType == 1 && data.IsSelfComplate == 1) || data.AssessmentType == 3"
                                type="number" label="考核评分：" placeholder="请输入" readonly
                                :class="val.ManUser_Score == val.Child_Score_Max ? '' : 'fontred'" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="item" v-if="data.AssessmentType == 1 || data.AssessmentType == 2">
                <div class="bigTit">考核备注</div>
                <div class="bigTopic">
                    {{ data.Man_Remark }}
                </div>
            </div>

            <div class="item">
                <div class="bigTit">总分</div>
                <div class="scoreBig">
                    <div class="" v-if="data.AssessmentType == 1 || data.AssessmentType == 2">
                        <span>自评总分：</span>
                        <label>{{ allSelfDivide }}</label>
                    </div>
                    <div class="" v-if="data.AssessmentType == 1 || data.AssessmentType == 3">
                        <span>考核评总分：</span>
                        <label>{{ allDivide }}</label>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="btn">
            <van-button type="info" style="width: 90%;" @click="submit">提交</van-button>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: this.whale.user.get(),
            data: {},
            allDivide: 0,
            allSelfDivide: 0,
        }
    },
    mounted() {
        this.getInfo()
    },
    methods: {
        getInfo() {
            let self = this;
            this.whale.remote.getResult({
                url: "/api/Mobile/PFM/PfmRecord/GetAssessmentDetails?guid=" + self.$route.query.guid + '&teacherId=' + self.user.ID,
                completed(m) {
                    console.log(m)
                    self.data = m.DATA
                    self.refashMaxScore()
                },
            });
        },

        refashMaxScore() {
            var self = this;
            self.allDivide = 0;
            self.allSelfDivide = 0;
            self.data.TemplateParents.forEach((item) => {
                if (item.Is_SetScore) {
                    self.allDivide += Number(item.ManUser_Score);
                    self.allSelfDivide += Number(item.Self_Assessment_Score);
                } else {
                    item.Children.forEach((val) => {
                        self.allDivide += Number(val.ManUser_Score);
                        self.allSelfDivide += Number(val.Self_Assessment_Score);
                    });
                }
            });
        },
        // changeScore(val, e, i) {
        //     if (val > e) {
        //         this.data.TemplateParents[i].ManUser_Score = e
        //     }
        // },
        // changeScoreSelf(val, e, i) {
        //     if (val > e) {
        //         this.data.TemplateParents[i].Self_Assessment_Score = e
        //     }
        // },


        // changeChild(val, e, p, i) {
        //     if (val > e) {
        //         this.data.TemplateParents[p].Children[i].ManUser_Score = e
        //     }
        // },
        // changeChildSelf(val, e, p, i) {
        //     if (val > e) {
        //         this.data.TemplateParents[p].Children[i].Self_Assessment_Score = e
        //     }
        // },

        // submit() {
        //     if (this.data.AssessmentType == 2) {
        //         this.selfSubmit()
        //     } else if (this.data.AssessmentType == 3) {
        //         this.checkSubmit()
        //     } else {
        //         if (this.data.IsSelfComplate == 0) {
        //             this.selfSubmit()
        //         } else {
        //             this.checkSubmit()
        //         }
        //     }
        // },
        // selfSubmit() {
        //     let data = {
        //         IsComplete: 0,
        //         IsSelfComplate: 1,
        //         Assessment_Guid: this.data.Assessment_Guid,
        //         MainId: this.data.Record_Main_Id,
        //         assRecords: []
        //     }
        //     this.data.TemplateParents.map(item => {
        //         if (item.Children.length) {
        //             item.Children.map(val => {
        //                 let obj = {
        //                     Assessment_Details_Id: val.ID,
        //                     Self_Assessment_Score: val.Self_Assessment_Score,
        //                     ManUser_Score: val.ManUser_Score
        //                 }
        //                 data.assRecords.push(obj)
        //             })
        //         } else {
        //             let obj = {
        //                 Assessment_Details_Id: item.ID,
        //                 Self_Assessment_Score: item.Self_Assessment_Score,
        //                 ManUser_Score: item.ManUser_Score
        //             }
        //             data.assRecords.push(obj)
        //         }
        //     })

        //     let self = this;
        //     this.whale.remote.getResult({
        //         url: "/api/Mobile/PFM/PfmRecord/CreateOrUpdateScore",
        //         data: data,
        //         completed() {
        //             self.$router.push("/check/back")
        //         },
        //     });
        // },
        // checkSubmit() {
        //     let data = {
        //         IsComplete: 1,
        //         IsSelfComplate: 1,
        //         Assessment_Guid: this.data.Assessment_Guid,
        //         MainId: this.data.Record_Main_Id,
        //         assRecords: []
        //     }
        //     this.data.TemplateParents.map(item => {
        //         if (item.Children.length) {
        //             item.Children.map(val => {
        //                 let obj = {
        //                     Assessment_Details_Id: val.ID,
        //                     Self_Assessment_Score: val.Self_Assessment_Score,
        //                     ManUser_Score: val.ManUser_Score
        //                 }
        //                 data.assRecords.push(obj)
        //             })
        //         } else {
        //             let obj = {
        //                 Assessment_Details_Id: item.ID,
        //                 Self_Assessment_Score: item.Self_Assessment_Score,
        //                 ManUser_Score: item.ManUser_Score
        //             }
        //             data.assRecords.push(obj)
        //         }
        //     })
        //     let self = this
        //     this.whale.remote.getResult({
        //         url: 'api/Mobile/PFM/PfmRecord/ManCreateOrUpdateScore',
        //         data: data,
        //         completed() {
        //             self.$router.push("/check/back")
        //         }
        //     })
        // }
    },
}
</script>
<style lang="less" scoped>
.fontred {
    -webkit-text-fill-color: red !important;
}

.fontash {
    -webkit-text-fill-color: #bdbdbd !important;
}

.top {
    background: #fff;
    padding-top: 10px;

    .title {
        width: 80%;
        margin: 20px auto;
        color: #0080ee;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
    }

    .tips {
        color: #222222;
        border-bottom: 1px dashed #222222;
        padding-bottom: 20px;
        width: 90%;
        margin: 0 auto;
        font-size: 15px;
    }

    .info {
        color: #98a3ad;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
        font-size: 14px;
        padding: 10px;
    }
}

.center {
    background: #fff;
    padding-bottom: 10px;

    .item {
        .bigTit {
            background: #f0f9ff;
            border-left: 3px solid #0195ff;
            font-weight: bold;
            padding: 10px 0 10px 20px;
            color: #0195ff;
            margin-bottom: 10px;
        }

        .bigTopic {
            background: #f6f7fb;
            width: 90%;
            margin: 0 auto;
            padding: 20px;
            box-sizing: border-box;
            margin-bottom: 10px;
        }

        .childBox {
            width: 90%;
            margin: 0 auto;

            .child {
                .smallTit {
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                .smallTopic {
                    background: #f6f7fb;
                    /* width: 90%; */
                    margin: 0 auto;
                    padding: 20px;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                }
            }
        }

        .scoreBig {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #fafafa;
            padding: 20px;
            width: 90%;
            box-sizing: border-box;
            margin: 0 auto;

            >div {
                width: 50%;

                &:nth-child(2) {
                    text-align: right;
                }
            }

            label {
                width: 40%;
                color: #1ea0fa;
                font-size: 20px;
                text-align: left;
            }
        }
    }
}

.btn {
    width: 100%;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}
</style>